import { defineStore } from 'pinia'
import { FormField } from '../components/input/FormField'
import { useProductsStore } from './ProductSelection';
import { menuItemType, usePageSelectionEA5 } from './PageSelectionEA5'

export class Commission {
    public TotalAmount = '';
    public CommissionAmount = '';
    public NumberOfPayments = 0;
    public Fix = 0.0;
    public Percentage = 0.0;
}

export interface ShouldDo3DSecureResult {
    ShouldDo: boolean;
    ShouldNotDoECICode: number;
}

export interface CanDo3DSecureResult {
    /** Main indicator if the deal, with the card information, can start a 3DSecure Authentication process. */
    CanDo: boolean;

    /**This is set only if "CanDo" property equals false.
     * This indicates if deal can skip Authentication and attempt charge or notify user to change card 
    */
    CanSkipAuthentication: boolean;
}

export interface Cardcom3DSecureResponse {
    ResponseCode: Cardcom3DSecureResponseCode;
    UniqueResponseId: string;
    Is3DS_valid_payment: boolean;
    threeDSMethodData: string;
    ErrorDescToUser: string;
    RedirectUrl: string;
    _3ds_version: string;
    _3ds_method: string;
    _3ds_acsurl: string;
    _3ds_trxid: string;
    _3ds_cavv: string;
}

export enum Cardcom3DSecureResponseCode {
    PassOver3DSecureAuth = -1,
    Unknown = 0,
    Auth = 1,
    FingerPrint = 2,
    CardHolderChellenge = 3,
    FailAuth = 4,
    FailError = 5,
    RejectAuth = 6,
}

export interface Cardcom3DSecureRequest {
    TerminalNum: number;
    CardholderFullName: string;
    CardHolderEmail: string;
    CVV: string;
    CardNumber: string;
    CardExpireMonth: string;
    CardExpireYear: string;
    CoinId: number;
    RequestedBillingAmount: number;
    CardholderPhone: string;
    UserAgentHeader: string;
    browserLanguage: string;
    _3ds_browserjavaenabled: string;
    _3ds_browserscreenheight: string;
    _3ds_browserscreenwidth: string;
    _3ds_browsercolordepth: string;
    _3ds_challengewindowsize: string;
    _3ds_channel: string;
    _3ds_category: string;
    _3ds_transtype: string;
    ExternalAisurit4DealPageUID: string;
    DealToSaveJSON: string;
    GooglePayToken: string;
    DirectDebitSplitPaymentsSum: number;
}

export enum DonationType {
    NotActive = 0,
    RecurringCharge = 1,
    MultipleCharges = 2,
    SingleCharge = 3
}

export const usePaymentStore = defineStore('PaymentStore', {
    state: () => {
        return {
            Comments: '',
            ButtonText: '',
            ButtonTextZeroSum: '',
            HideCreditCardFieldsOnZeroSum: false,
            EmailField: <FormField>{},
            MobileField: <FormField>{},
            CardNumberField: <FormField>{},
            YearsField: <FormField>{},
            MonthsField: <FormField>{},
            CvvField: <FormField>{},
            CardOwnerNameField: <FormField>{},
            CardTZField: <FormField>{},
            PaymentsField: <FormField>{},
            OpenSumField: <FormField>{},
            CardOwnerCityField: <FormField>{},
            CardOwnerAddressField: <FormField>{},
            CardOwnerZipcodeField: <FormField>{},
            ApprovedTermsAndConditions: false,
            ShowTermsAndConditions: false,
            TermsAndConditionsUrl: '',
            AllowMerchantMessages: false,
            MustAllowMerchantMessages: false,
            ShowMerchantMessages: false,
            ShowPayPal: false,
            ShowUpayBitButton: false,
            ShowPayMeBitButton: false,
            ShowCardcomBitButton: false,
            ShowApplePay: false,
            ShowGooglePay: false,
            ShowRecaptcha: false,
            RecaptchaVersion: "",
            IsCredit: false,
            NumOfRetriesAllowed: 0,
            Commissions: <Array<Commission>>[],
            Is3DSecureEnabled: false,
            Was3DSActiveOnPageLoad: false,
            ThreeDSecureTransactionId: '',
            ShowSuccessPopup: false,
            ShowErrorPopup: false,
            DonationType: DonationType.NotActive,
            ThreeDSecureCavv: '',
            //Saving the decrypted card details in the store from Google for 3DS
            GoogleCardNumber: '',
            GoogleExpirationYear: '0',
            GoogleExpirationMonth: '0',
            CanSkipAuthentication: false,
            CartTitle: '',
            IsDirectDebit: false,
            EnableHooxOffers: false,
            HooxID: '',
            SupportedCardBrands: <Array<string>>[],
            IsDirectDebitSplitPayments: false,
            ApplePayApplicationData: '',
            ShowPaymentButton: true,
            OrderSummeryTitle: ''
        }
    },
    getters: {
        ShouldShowCreditCardFields(state) {
            const ProductsStore = useProductsStore();

            return !(state.HideCreditCardFieldsOnZeroSum && ProductsStore.totalItemsInCart != 0 && ProductsStore.totalCart == 0);
        },
        CheckAndSetPaymentNavbarText(state) {
            const PageSelectionStore = usePageSelectionEA5();

            if (!this.ShouldShowCreditCardFields && !(state.ShowTermsAndConditions && state.ShowMerchantMessages && state.ShowRecaptcha)) {

                // change Payment navbar item's text
                const paymentPageItem = PageSelectionStore.menuItems.find(item => item.menuType == menuItemType.Payment);
                if (paymentPageItem != undefined) {
                    paymentPageItem.i18Name = "ps.Summery";
                    paymentPageItem.i18NameAlt = "ps.SummeryAlt";
                }
            }
            else {

                // change Payment navbar item's text
                const paymentPageItem = PageSelectionStore.menuItems.find(item => item.menuType == menuItemType.Payment);
                if (paymentPageItem != undefined) {
                    paymentPageItem.i18Name = "ps.Payment";
                    paymentPageItem.i18NameAlt = "ps.PaymentAlt";
                }
            }

        }
    }
})


