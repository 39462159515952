import { useCommonStore } from "../../Store/CommonStore";

const CardcomBitService = {

    CreateNewBitSale(request: any) {
        const data = JSON.stringify(request)

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/CardcomBit/CreateNewBitSale`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    },

    WaitForBitPayment(paymentInitiationId: string, intervalId: number, onSuccess: () => void, onFail: (errorData: any) => void) {
        const CommonStore = useCommonStore();

        if (CommonStore.CurrentCardcomBitIntervalId == intervalId) {
            this.GetBitSaleDetails(paymentInitiationId).then(
                (response) => {
                    // If error
                    if (response.HasError) {
                        onFail(response);
                        return;
                    }

                    // 4 = Interim status between receiving an instruction and performing an action on the transaction until the end of that action
                    // 12 = A request for payment was established by the business.
                    // 17 = Customer has not yet joined the transaction (did not scan the QR / did not enter the SMS link)
                    const isWaiting = response.RequestStatusCode == 4 || response.RequestStatusCode == 12 || response.RequestStatusCode == 17;

                    // 9 = Credit extension performed. Awaiting instruction to make payment by the business
                    const isCompletedInBitApp = response.RequestStatusCode == 9;

                    if (isWaiting) {
                        this.WaitForBitPayment(paymentInitiationId, intervalId, onSuccess, onFail);
                    }
                    else if (isCompletedInBitApp) {
                        onSuccess();
                        return;
                    }
                    else if (!isWaiting && !isCompletedInBitApp) {
                        onFail(response);
                        return;
                    }

                })
                .catch((error) => {
                    console.error(error);
                    return;
                });
        }

    },

    ChargeBitSale(totalPrice: number, externalSystemReference: string, sourceTransactionId: number, paymentInitiationId: string) {
        const CommonStore = useCommonStore();

        const request = {
            TerminalNumber: CommonStore.TerminalNumber,
            TotalPrice: totalPrice,
            ExternalSystemReference: externalSystemReference,
            PaymentInitiationId: paymentInitiationId,
            SourceTransactionId: sourceTransactionId
        };
        const data = JSON.stringify(request)

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/CardcomBit/ChargeBitSale`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    },

    GetBitSaleDetails(paymentInitiationId: string) {

        const CommonStore = useCommonStore();

        const request = {
            TerminalNumber: CommonStore.TerminalNumber,
            PaymentInitiationId: paymentInitiationId
        };
        const data = JSON.stringify(request);

        return fetch(`${process.env.VUE_APP_ROOT_API}/api/CardcomBit/GetBitSaleDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }).then(response => response.json()).
            then((response) => {
                return response;
            });
    },

    GetQrBarcodeUrl(qrBarcodeBytes: any) {
        if (qrBarcodeBytes) {
            const binaryString = atob(qrBarcodeBytes);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes.buffer], { type: "image/jpeg" });
            const imageUrl = URL.createObjectURL(blob);
            return imageUrl;
        }
        else {
            return '';
        }
    }

}

export default CardcomBitService;