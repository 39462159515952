import { defineStore } from 'pinia'
import { computed } from 'vue';
import { CouponService, StoreService } from '../common/Services';
import { FormField } from '../components/input/FormField';
import { SelectKeyValue } from '../components/input/InputSelect.vue';
import { useCommonStore } from "../Store/CommonStore";
import { DonationType, usePaymentStore } from './PaymentStore';
import { ProductUtils } from '../common/Utils'

export class BaseItem {
    public uid = "x";
    public imageUrl = "";
    public name = "";
    public description = "";
    public BasePrice = 0;
    public PriceBeforeDiscount = 0;
    public BaseISOCurrency = 0;
    public PriceToShow = 0;
    public PriceBeforeDiscountToShow = 0;
    public IsCurrent = false;
}

export class Product extends BaseItem {
    public MinQuantity = 0;
    public MaxQuantity = 0;
    public JumpsInQuantity = 1;
    public SelectedBuyQuantity = 0.0;
    public QuantityOptions = <Array<SelectKeyValue>>[];
    public Comments = '';
    public IsManualSum = false;
    public PaymentsOptions = <Array<SelectKeyValue>>[];
    public IsCouponActive = false;
    public ManualPrice = 0;
    public ManualPriceTxt = '0';
    public IsVatFree = false;
    public SpecialPrices = <Array<SpecialPrice>>[];
    public PreConfiguredPrice: number | undefined;
    public IsPreSelected = false;
    public CurrentInventory = 0;
    public CheckInventory = false;
}

export class SpecialPrice {
    public QuantityFrom = 0;
    public QuantityTo = 0;
    public Cost = 0;
}

export class DeliveryMethod extends BaseItem {
    public RowId = 0;
    public AddressRequired = false;
    public ShowAddressFields = false;
}

export class ISOCurrency {
    public Code = 0;
    public AlphabeticCode = "";
    public ValueToNis = 0.0;
    public NameHeb = "";
    public NameEn = "";
    public CoinSing = "";
}

export enum DiscountType {
    Sum = 0,
    Percentage = 2
}

export enum CouponType {
    GroupCoupon = 1,
    ItemCoupon = 2
}

export class Coupon {
    public IsActivatedByUser = false; // The user has activated the coupon in the cart
    public IsActive = false; // Is Shop owner activat the coupon option
    public CouponCode = ""; // the code of the coupon from user
    public IsValid = false; // is the coupon valid - via chack from the server
    public DiscountType = DiscountType.Sum;
    public DiscountValue = 0; // in Sum or In Percentage!.
    public CouponType = CouponType.GroupCoupon;
}

export const useProductsStore = defineStore('ProductsStore', {
    state: () => {

        return {
            Title: '',
            isDonation: false,
            showCurrencyConvert: false,
            currencies: <Array<ISOCurrency>>[],
            selectedCurrency: new ISOCurrency(),
            coupon: new Coupon(),
            couponCalculatedDiscount: 0,
            CurrentCouponCode: '',
            products: <Array<Product>>[],
            deliveryMethods: <Array<DeliveryMethod>>[],
            haveImages: false, /*האם הפריטים כוללים תמונות יוצג עיצוב שונה*/
            isCouponActive: false,
            ShowProductPrice: false,
            ShowTotalPrice: false,
            ItemsDisplayType: 0,
            ManualSumField: new FormField(),
            ShowVatInfo: false,
            Vat: 0.0,
            TotalPriceNotRequireVat: 0.0,
            TotalPriceRequireVat: 0.0,
            TotalVat: 0.0,
            DiscountSum: 0.0,
            ShowDonationAmountsButtons: false,
            DonationDescription: ''
        }
    },
    getters: {
        productsInCart(state) {
            return state.products.filter(function (itm) { return itm.SelectedBuyQuantity > 0 });
        },
        totalItemsInCart(state) {
            const selectedProducts = this.productsInCart;
            let totalProductCount = 0;
            selectedProducts.forEach((item) => { totalProductCount += item.SelectedBuyQuantity });
            return totalProductCount;
        },
        totalItemsPrice(state) {
            let total = 0.0;
            this.productsInCart.forEach((item) => {
                total += (Math.round(item.PriceToShow * item.SelectedBuyQuantity * 100) / 100);
            });
            return total;
        },
        deliveryPrice(state) {
            let price = 0.0;
            state.deliveryMethods.forEach((delivery) => {
                if (delivery.IsCurrent)
                    price = (Math.round(delivery.PriceToShow * 1 * 100) / 100);
            });
            return price;
        },
        totalCart() {
            let total = 0.0;
            total += this.totalItemsPrice;
            total -= this.couponCalculatedDiscount;
            total += this.deliveryPrice;

            let totalNotRequireVatTemp = 0.0
            let totalRequireVatTemp = 0.0;
            let totalBeforeDiscountTemp = 0.0;
            let totalAfterDiscountTemp = 0.0;

            this.TotalPriceRequireVat = 0.0;
            this.TotalPriceNotRequireVat = 0.0;
            this.TotalVat = 0.0;

            const selectedProductsTemp = this.products.filter(product => product.SelectedBuyQuantity > 0);
            selectedProductsTemp.forEach((prod) => {
                const prodTotalPrice = Math.round(prod.PriceToShow * prod.SelectedBuyQuantity * 100) / 100;

                if (prod.IsVatFree == false) {
                    totalRequireVatTemp += prodTotalPrice;
                }
                else {
                    totalNotRequireVatTemp += prodTotalPrice;
                }

                totalBeforeDiscountTemp += prodTotalPrice;
                totalAfterDiscountTemp += prodTotalPrice - this.couponCalculatedDiscount;
            });

            totalRequireVatTemp += this.deliveryPrice;

            // הנחה באחוזים
            if (this.ItemsDisplayType == 2 && this.coupon.DiscountType == 2) {
                const totalNotReqVatDiscoTemp = Math.round((totalNotRequireVatTemp * (this.coupon.DiscountValue / 100)) * 100) / 100;
                this.TotalPriceNotRequireVat = totalNotRequireVatTemp - totalNotReqVatDiscoTemp;

                const totalReqVatDiscoTemp = Math.round((totalRequireVatTemp * (this.coupon.DiscountValue / 100)) * 100) / 100;
                this.TotalPriceRequireVat = totalRequireVatTemp - totalReqVatDiscoTemp;

                this.DiscountSum = totalReqVatDiscoTemp + totalNotReqVatDiscoTemp;
            }
            // הנחה של סכום מסויים
            else {
                if (totalRequireVatTemp == 0) { // 1 all from totalNotReqVatTemp
                    this.TotalPriceNotRequireVat = totalNotRequireVatTemp - this.couponCalculatedDiscount;
                    this.TotalPriceRequireVat = 0.0;
                } else if (totalNotRequireVatTemp == 0) { // 2 all from totalReqVatTemp
                    this.TotalPriceNotRequireVat = 0.0;
                    this.TotalPriceRequireVat = totalRequireVatTemp - this.couponCalculatedDiscount;
                } else { // 3 - מהגדול ביותר
                    if (totalRequireVatTemp >= totalNotRequireVatTemp) {
                        this.TotalPriceNotRequireVat = totalNotRequireVatTemp;
                        this.TotalPriceRequireVat = totalRequireVatTemp - this.couponCalculatedDiscount;
                    } else {
                        this.TotalPriceNotRequireVat = totalNotRequireVatTemp - this.couponCalculatedDiscount;
                        this.TotalPriceRequireVat = totalRequireVatTemp;
                    }
                }

                this.DiscountSum = this.couponCalculatedDiscount;
            }

            // חישוב מע"מ
            if (totalRequireVatTemp > 0) {
                const beforVat = Math.round((this.TotalPriceRequireVat / (1 + this.Vat)) * 100) / 100;
                this.TotalVat = this.TotalPriceRequireVat - beforVat;// חילוץ מע"מ מסה"כ
                this.TotalPriceRequireVat = beforVat;// החסרת מע"מ מסהכול כולל
            } else { // הוספת מעם לסה"כ
                this.TotalVat = this.TotalPriceRequireVat * this.Vat;
            }

            return total;
        }
    },
    actions: {
        ChangeCurrency(originCurrency: ISOCurrency, targetCurrency: ISOCurrency) {
            if (originCurrency == targetCurrency)
                return;

            this.selectedCurrency = targetCurrency;

            this.products.forEach(prod => {
                prod.PriceToShow = this.ConvertCurrency(prod.BasePrice, prod.BaseISOCurrency, originCurrency, targetCurrency);
                prod.PriceBeforeDiscountToShow = this.ConvertCurrency(prod.PriceBeforeDiscount, prod.BaseISOCurrency, originCurrency, targetCurrency);
            });

            this.deliveryMethods.forEach(delivery => {
                delivery.PriceToShow = this.ConvertCurrency(delivery.BasePrice, delivery.BaseISOCurrency, originCurrency, targetCurrency);
                delivery.PriceBeforeDiscountToShow = this.ConvertCurrency(delivery.PriceBeforeDiscount, delivery.BaseISOCurrency, originCurrency, targetCurrency);
            });

            const couponBaseISOCurrencyCode = 1; // NIS - In the future should be returned from server in the Coupon object
            if (this.coupon?.DiscountType == DiscountType.Sum)
                this.couponCalculatedDiscount = this.ConvertCurrency(this.coupon.DiscountValue, couponBaseISOCurrencyCode, originCurrency, targetCurrency);
            if (this.coupon?.DiscountType == DiscountType.Percentage)
                this.couponCalculatedDiscount = Math.round(this.totalItemsPrice * this.coupon.DiscountValue) / 100;
        },
        ConvertCurrency(basePrice: number, baseCurrencyCode: number, originCurrency: ISOCurrency, targetCurrency: ISOCurrency) {
            let translatedTotal = basePrice / targetCurrency.ValueToNis;
            if (baseCurrencyCode != 1) {
                translatedTotal *= originCurrency.ValueToNis;
            }
            return Math.round(translatedTotal);
        },
        ValidateCoupon(couponCode: string) {
            this.couponCalculatedDiscount = 0;

            const CommonStore = useCommonStore();
            const PaymentStore = usePaymentStore();

            return CouponService.GetCoupon(CommonStore.Uid, couponCode).
                then((response) => {
                    if (response.IsError) {
                        return response;
                    }

                    if (response.Coupon != null && response.Coupon != undefined && response.Coupon.IsActive && response.Coupon.IsValid) {

                        // drop down list or radio buttons
                        if (this.ItemsDisplayType == 0 || this.ItemsDisplayType == 1) {
                            this.ApplyItemCouponDiscount(response.Coupon);
                        }

                        // shopping cart
                        if (this.ItemsDisplayType == 2) {
                            this.ApplyGroupCouponDiscount(response.Coupon);
                        }

                        return response;
                    }
                    else {
                        return response;
                    }
                }).
                catch((error) => {
                    return { 'IsError': true, 'ErrorMessage': error, 'Coupon': null };
                });
        },
        ApplyItemCouponDiscount(coupon: Coupon) {
            if (coupon != null && coupon != undefined) {
                this.coupon = coupon;

                const selectedProduct = this.products.find(product => product.SelectedBuyQuantity > 0);
                if (selectedProduct != undefined) {
                    this.couponCalculatedDiscount = (selectedProduct.PriceToShow - coupon.DiscountValue) * selectedProduct.SelectedBuyQuantity;

                    //selectedProduct.PriceToShow = coupon.DiscountValue;
                }
            }
        },
        ApplyGroupCouponDiscount(coupon: Coupon) {
            if (coupon != null && coupon != undefined) {
                this.coupon = coupon;

                if (coupon.DiscountType == DiscountType.Sum)
                    this.couponCalculatedDiscount = coupon.DiscountValue;
                if (coupon.DiscountType == DiscountType.Percentage)
                    this.couponCalculatedDiscount = Math.round(this.totalItemsPrice * coupon.DiscountValue) / 100;

            }
        },
        RemoveCoupon() {
            this.coupon = new Coupon();
            this.couponCalculatedDiscount = 0;
            this.CurrentCouponCode = "";

            // drop down list or radio buttons
            if (this.ItemsDisplayType == 0 || this.ItemsDisplayType == 1) {

                // return product price to its default value
                const selectedProduct = this.products.find(product => product.SelectedBuyQuantity > 0);
                if (selectedProduct != undefined) {
                    selectedProduct.PriceToShow = selectedProduct.BasePrice;
                }
            }
        },
        AddNextQuantity(prod: Product) {
            // if product added to the cart for the first time:
            // set selected quantity as the minimum quantity
            if (prod.SelectedBuyQuantity == 0) {
                prod.SelectedBuyQuantity = prod.MinQuantity;
                return;
            }

            const quantityToAdd = 1 * prod.JumpsInQuantity;

            prod.SelectedBuyQuantity += quantityToAdd;

            if (prod.SelectedBuyQuantity >= prod.MaxQuantity) {
                prod.SelectedBuyQuantity = prod.MaxQuantity;
            }

            if (this.coupon.CouponCode) {
                // drop down list or radio buttons
                if (this.ItemsDisplayType == 0 || this.ItemsDisplayType == 1) {
                    this.ApplyItemCouponDiscount(this.coupon);
                }

                // shopping cart
                if (this.ItemsDisplayType == 2) {
                    this.ApplyGroupCouponDiscount(this.coupon);
                }
            }


            ProductUtils.CheckSpecialPrice(prod);

        },
        PrevQuantity(prod: Product) {
            const quantityToRemove = 1 * prod.JumpsInQuantity;

            prod.SelectedBuyQuantity -= quantityToRemove;

            if (prod.SelectedBuyQuantity < prod.MinQuantity) {
                prod.SelectedBuyQuantity = 0;
            }

            if (this.coupon.CouponCode) {
                // drop down list or radio buttons
                if (this.ItemsDisplayType == 0 || this.ItemsDisplayType == 1) {
                    this.ApplyItemCouponDiscount(this.coupon);
                }

                // shopping cart
                if (this.ItemsDisplayType == 2) {
                    this.ApplyGroupCouponDiscount(this.coupon);
                }
            }

            ProductUtils.CheckSpecialPrice(prod);

            // remove coupon on empty cart
            if (this.totalItemsInCart == 0) {
                this.RemoveCoupon();
            }

        },
        AddQuantity(product: Product, productName: string) {
            if (product != undefined) {
                const wantedQuantity = product.SelectedBuyQuantity + product.JumpsInQuantity;

                if (wantedQuantity <= product.MaxQuantity)
                    this.AddNextQuantity(product);
            }

            if (productName != undefined) {
                this.products.forEach((product) => {
                    if (product.name == productName && product.SelectedBuyQuantity < product.MaxQuantity)
                        this.AddNextQuantity(product);
                });
            }

        },
        RemoveQuantity(product: Product, productName: string) {
            if (product != undefined) {
                const wantedQuantity = product.SelectedBuyQuantity - product.JumpsInQuantity;

                // don't allow removing quantity if the product is pre-selected and the current quantity is the minimum quantity
                if (wantedQuantity < product.MinQuantity && product.IsPreSelected)
                    return;

                if (this.ItemsDisplayType != 2 && product.SelectedBuyQuantity > product.MinQuantity)
                    this.PrevQuantity(product);
                if (this.ItemsDisplayType == 2 && product.SelectedBuyQuantity >= product.MinQuantity)
                    this.PrevQuantity(product);
            }
            if (productName != undefined) {
                this.products.forEach((product) => {
                    if (product.name == productName) {
                        if (this.ItemsDisplayType != 2 && product.SelectedBuyQuantity > product.MinQuantity)
                            this.PrevQuantity(product);
                        if (this.ItemsDisplayType == 2 && product.SelectedBuyQuantity >= product.MinQuantity)
                            this.PrevQuantity(product);
                    }
                });
            }
        },
        GetDefaultPaymentValue(product: Product) {
            const PaymentStore = usePaymentStore();

            if (PaymentStore.DonationType == DonationType.NotActive) {
                if (product.PaymentsOptions.length > 0)
                    return product.PaymentsOptions[0].value;
                else
                    return 1;
            }

            if (PaymentStore.DonationType == DonationType.RecurringCharge)
                return 0;

            if (this.ItemsDisplayType == 2) {
                if (PaymentStore.DonationType == DonationType.SingleCharge) {
                    const options = PaymentStore.PaymentsField.Data.SelectList.filter(o => o.value != '0');
                    if (options.length > 0)
                        return options[0].value;
                    else
                        return 1;
                }
                if (PaymentStore.DonationType == DonationType.MultipleCharges) {
                    const options = PaymentStore.PaymentsField.Data.SelectList.filter(o => o.value != '0' && o.value != '1');
                    if (options.length > 0)
                        return options[0].value;
                    else
                        return 1;
                }
            }
            else {
                if (PaymentStore.DonationType == DonationType.SingleCharge) {
                    const options = product.PaymentsOptions.filter(o => o.value != '0');
                    if (options.length > 0)
                        return options[0].value;
                    else
                        return 1;
                }
                if (PaymentStore.DonationType == DonationType.MultipleCharges) {
                    const options = product.PaymentsOptions.filter(o => o.value != '0' && o.value != '1');
                    if (options.length > 0)
                        return options[0].value;
                    else
                        return 1;
                }
            }
        },


    }
})