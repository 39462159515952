import { computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { useCommonStore } from "../../../Store/CommonStore";
import { useCompanyInfoStore } from "../../../Store/CompanyInfoStore";
import { useDeliveryStore } from "../../../Store/DeliveryStore";
import { useDetailsStore } from "../../../Store/DetailsStore";
import { useDocumentStore } from "../../../Store/DocumentStore";
import { usePaymentStore } from "../../../Store/PaymentStore";
import { useProductsStore } from "../../../Store/ProductSelection";
import { FormUtils } from "../../Utils"
import ApplePayJS from "./ApplePayJS";

enum PaymentAuthorizationResultAction {
    Success = 0,
    FailSoCancel = 1,
    FailButRetry = 2,
}

class ApplePayManager {
    // private properties
    private _merchantIdName = process.env.VUE_APP_APPLE_MERCHANT_ID as string;
    private _isSupportedByDevice: boolean = false;
    private _canMakePayments: boolean = false;
    private _isSupportsSetup: boolean = false;
    private _isIframeCrossOriginDetected: boolean = false;
    private _applePayVersion: number;
    private _session: ApplePaySession;
    private _paymentAuthResultArray = Array<ApplePayJS.ApplePayPaymentAuthorizationResult>();
    private _isApplePayExt: boolean;

    // public properties
    public StoreName: string;
    public CountryCode: string;
    public CurrencyCode: string;
    public ApplePayPaymentToken: ApplePayJS.ApplePayPaymentToken;
    public PaymentPageWindow = window;
    public ExtPopupWindow: Window | null = window
    public GoogleToken: string;
    public OrderLines: ApplePayJS.ApplePayLineItem[] = [];
    public ContactDetails: ApplePayJS.ApplePayPaymentContact | undefined = {};
    public RequiredContactFields: ApplePayJS.ApplePayContactField[] = [];
    public AvailableCardBrands: string[];

    // store content holders
    public t = useI18n();
    public CompanyInfoStore = useCompanyInfoStore();
    public ProductsStore = useProductsStore();
    public DeliveryStore = useDeliveryStore();
    public DocumentStore = useDocumentStore();
    public DetailsStore = useDetailsStore();
    public PaymentStore = usePaymentStore();
    public CommonStore = useCommonStore();

    // public flags for checking relevant ApplePay info
    ShowApplePay: ComputedRef<boolean> = computed(() => {
        return this._isSupportedByDevice
    });
    ShowPaymentBtn: ComputedRef<boolean> = computed(() => {
        return this._isSupportedByDevice && !this._isIframeCrossOriginDetected && this._canMakePayments
    });
    ShowSetupBtn: ComputedRef<boolean> = computed(() => {
        return this._isSupportedByDevice && !this._isIframeCrossOriginDetected && this._isSupportsSetup && !this._canMakePayments
    });
    ShowPopupBtn: ComputedRef<boolean> = computed(() => {
        return this._isSupportedByDevice && this._isIframeCrossOriginDetected
    });

    constructor(processAuthedPaymentTokenHandler?: (token: ApplePayJS.ApplePayPayment) => any) {
        this.StoreName = this.CompanyInfoStore.Name;

        // Set the appropriate ISO country and currency codes
        this.CountryCode = 'IL';
        this.CurrencyCode = this.ProductsStore.selectedCurrency.AlphabeticCode;

        this.InitFlags();
        this.CheckIfApplePayExtPage();

        // Set the ApplePay JS version to use
        const highestSupportableVersion = this.GetHighestSupportableVersion();
        this._applePayVersion = highestSupportableVersion || 8;

        if (processAuthedPaymentTokenHandler)
            this.ProcessAuthedPaymentTokenHandler = processAuthedPaymentTokenHandler;

        this.AvailableCardBrands = this.PaymentStore.SupportedCardBrands;
    }

    InitFlags() {
        this._isSupportedByDevice = "ApplePaySession" in window && ApplePaySession !== undefined;

        if (this._isSupportedByDevice == false) {
            return;
        }

        try {
            this._canMakePayments = ApplePaySession.canMakePayments();

            if (this._canMakePayments == false) {
                ApplePaySession.canMakePaymentsWithActiveCard(this._merchantIdName)
                    .then(ans => this._canMakePayments = ans);
            }
        } catch (error) {
            console.error(error);
        }

        try {
            this._isSupportsSetup = "openPaymentSetup" in ApplePaySession;
        } catch (error) {
            console.error(error);
        }

        try { ApplePaySession.canMakePayments(); } // called with the sole intention of producing an exception (when in iframe)
        catch (ex) {
            const domEx = ex as DOMException;
            const iframeErrorMsg = 'different security origin than its top-level frame'

            const matches = domEx.name.match(/InvalidAccessError/ig);

            if (domEx.message.indexOf(iframeErrorMsg) >= 0 && matches != null && matches.length >= 1)
                this._isIframeCrossOriginDetected = true;
        }
    }

    GetHighestSupportableVersion() {
        if (this._isSupportedByDevice === false) {
            return -1;
        }

        // future proofing the value - latest & current version: 10 for apple-pay (as of 30-05-2021 - min: ios 14, macOs 11)
        let v = 15;

        for (v; v > 1; v--) {
            try {
                if (ApplePaySession.supportsVersion(v))
                    return v;
            }
            catch {
                return -1;
            }
        }

        throw 'browser supported, but no version could be identified (?)'; // should never happen
    }

    PushPaymentAuthResult(resultAction: PaymentAuthorizationResultAction, resultMessage?: string) {
        let uiResult: ApplePayJS.ApplePayPaymentAuthorizationResult;

        switch (resultAction) {
            case PaymentAuthorizationResultAction.Success:
                uiResult = {
                    status: ApplePaySession.STATUS_SUCCESS, // = sheet will display 'Done (V)' and closes.
                    errors: []
                };
                break;
            case PaymentAuthorizationResultAction.FailSoCancel:
                uiResult = {
                    status: ApplePaySession.STATUS_FAILURE,
                    errors: []  // empty array = sheet displays 'payment not complete (!)' and closes.
                };
                break;
            case PaymentAuthorizationResultAction.FailButRetry:
                uiResult = {
                    status: ApplePaySession.STATUS_FAILURE,
                    errors: [new ApplePayError("unknown", undefined, resultMessage || "some failure occured handling the token data")]
                };
                break;
            default:
                throw 'unexpected value for `PaymentAuthorizationResultAction` passed to `pushPaymentAuthResult(..)`';
        }

        this._paymentAuthResultArray.push(uiResult);

        this.ConsumePaymentAuthResult(uiResult);
    }

    ConsumePaymentAuthResult(authResult: ApplePayJS.ApplePayPaymentAuthorizationResult) {
        try {
            this._session.completePayment(authResult);
        }
        catch (ex) {
            console.error("failed to report authResult to UI", ex);
        }
    }

    // create new ApplePay session and display the ApplePay sheet to the customer
    BeginPaymentAP(applicationData?: string) {
        const request = this.GetApplePayPaymentRequest(applicationData);

        this._session = new ApplePaySession(this._applePayVersion, request);
        this._session.onvalidatemerchant = this.OnValidateMerchant;
        this._session.onpaymentauthorized = this.OnPaymentAuthorized;
        this._session.oncancel = () => { };

        this._session.begin();
    }

    // validation of the merchant details
    protected OnValidateMerchant = (event: ApplePayJS.ApplePayValidateMerchantEvent): void => {
        const data = {
            ValidationUrl: event.validationURL,
            StoreName: this.StoreName,
            MerchantId: this._merchantIdName,
            MerchantDomain: this.GetParentHostName(),
            SourceUrl: window.location.href
        };
        const request = JSON.stringify(data);

        const headers = {
            'Content-Type': 'application/json; charset=utf-8',
        };

        const url = `${process.env.VUE_APP_ROOT_API}/ApplePayAPI/MerchantValidation`;

        // Pull merchant session from server (using the merchant certificate)
        const action = fetch(url, {
            method: 'POST',
            headers: headers,
            body: request
        });

        action
            .then(response => response.json())
            .then((merchantSession) => {
                const merchantSessionJson = JSON.parse(merchantSession)

                // Complete validation by passing the merchant session to the ApplePay session.
                this._session.completeMerchantValidation(merchantSessionJson);
            })
            .catch((error) => {
                console.error('action failed, or took too long...', error);
            });
    }

    private GetParentHostName() {
        let MerchantDomain;
        try {
            if (window.location !== window.parent.location) {
                const originUrl = window.location.ancestorOrigins[0];
                MerchantDomain = new URL(originUrl).hostname;
            } else {
                MerchantDomain = window.location.hostname;
            }
        } catch {
            const originUrl = document.referrer;
            MerchantDomain = new URL(originUrl).hostname;
        }
        return MerchantDomain;
    }

    protected OnPaymentAuthorized = (event: ApplePayJS.ApplePayPaymentAuthorizedEvent): void => {
        // Get the payment data for use to capture funds from
        // the encrypted ApplePay token in your server.
        const payment = event.payment;

        const handler = this.ProcessAuthedPaymentTokenHandler || this.ProcessAuthedPaymentTokenHandlerDefault;
        const promise = handler(payment);

        promise.then(
            success => this.OnPaymentAuthorizedDone(success),
            failure => this.OnPaymentAuthorizedFailed(failure)
        );
    }

    OnPaymentAuthorizedDone = (response: any) => {
        this._session.abort();
    };

    OnPaymentAuthorizedFailed = (response: any) => {
        this._session.abort();
    };

    SetupApplePay() {
        return ApplePaySession.openPaymentSetup(this._merchantIdName)
            .then((setupResult) => {
                if (setupResult == true) {
                    this.InitFlags();
                }
                else {
                    //console.log("Setting up ApplePay has returned unsuccessful. cannot proceed with payment method...");
                }
                return setupResult;
            }
            ).catch((err: any) => {
                const errMsg = `Setting up ApplePay has thrown an error: ${JSON.stringify(err)}`;
                console.error(errMsg);
                return false;
            });
    }

    InitOrderLines() {
        let totalSum = 0.0;

        // add summery lines for selected products
        this.ProductsStore.productsInCart.forEach((product) => {
            const productTotalPrice = (Math.round(product.PriceToShow * product.SelectedBuyQuantity * 100) / 100);
            totalSum += productTotalPrice;
            this.OrderLines.push({ label: product.name, amount: productTotalPrice.toString(), type: 'final' });
        });

        // add summery line for selected delivery method (if any)
        const selectedDelivery = this.ProductsStore.deliveryMethods.find(delivery => delivery.IsCurrent);
        if (selectedDelivery != null && selectedDelivery != undefined && selectedDelivery.PriceToShow > 0) {
            const deliveryPrice = (Math.round(selectedDelivery.PriceToShow * 1 * 100) / 100);
            totalSum += deliveryPrice;
            this.OrderLines.push({ label: selectedDelivery.name, amount: deliveryPrice.toString(), type: 'final' });
        }

        // add summery line for coupon discount (if any)
        const coupon = this.ProductsStore.coupon;
        if (coupon != null && coupon != undefined && this.ProductsStore.couponCalculatedDiscount > 0) {
            const couponDiscount = this.ProductsStore.couponCalculatedDiscount * -1;
            totalSum += couponDiscount;
            this.OrderLines.push({ label: this.t.tm('m.CouponDiscount').toString(), amount: couponDiscount.toString(), type: 'final' });
        }

        // add summery line for payments commission (if any)
        if (this.PaymentStore.PaymentsField == null || this.PaymentStore.PaymentsField == undefined ||
            this.PaymentStore.PaymentsField.Value == null || this.PaymentStore.PaymentsField.Value == undefined ||
            this.PaymentStore.PaymentsField.Value.trim() == '') {
            return;
        }

        const numOfPayments = this.PaymentStore.PaymentsField.Value.trim();
        const commissions = this.PaymentStore.Commissions;
        if (numOfPayments != '' && commissions != undefined && commissions != null && commissions.length > 0) {
            const paymentsCommission = commissions.find(c => c.NumberOfPayments.toString() == numOfPayments);

            if (paymentsCommission != undefined) {
                const totalCommission = Math.round((totalSum * paymentsCommission.Percentage + paymentsCommission.Fix) * 100) / 100;
                if (totalCommission > 0) {
                    totalSum += totalCommission;
                    this.OrderLines.push({ label: this.t.tm('m.PaymentsCommission').toString(), amount: totalCommission.toString(), type: 'final' });
                }
            }
        }

        // calculate order total sum
        const p = Math.pow(10, 2);
        const totalOrderSum = Math.round(totalSum * p) / p;
        const orderTotal: ApplePayJS.ApplePayLineItem = {
            amount: totalOrderSum.toString(),
            label: this.StoreName,
            type: "final"
        };

        this.OrderLines.push(orderTotal);
    }

    InitContactDetails() {

        let name = FormUtils.GetName();
        let email = FormUtils.GetEmail();
        let mobilePhone = FormUtils.GetMobilePhone();
        let street = FormUtils.GetStreetAndNumber();
        let zipcode = FormUtils.GetZipCode();
        let city = FormUtils.GetCity();

        if (this.PaymentStore != null && this.PaymentStore != undefined) {

            // try to get name from credit card form
            if (!name)
                if (this.PaymentStore.CardOwnerNameField != null && this.PaymentStore.CardOwnerNameField != undefined)
                    if (this.PaymentStore.CardOwnerNameField.Value != null && this.PaymentStore.CardOwnerNameField.Value != undefined)
                        name = this.PaymentStore.CardOwnerNameField.Value.trim();

            // try to get email from credit card form
            if (!email)
                if (this.PaymentStore.EmailField != null && this.PaymentStore.EmailField != undefined)
                    if (this.PaymentStore.EmailField.Value != null && this.PaymentStore.EmailField.Value != undefined)
                        email = this.PaymentStore.EmailField.Value.trim();

            // try to get mobile number from credit card form
            if (!mobilePhone)
                if (this.PaymentStore.MobileField != null && this.PaymentStore.MobileField != undefined)
                    if (this.PaymentStore.MobileField.Value != null && this.PaymentStore.MobileField.Value != undefined)
                        mobilePhone = this.PaymentStore.MobileField.Value.trim();
        }

        if (this.DocumentStore != null && this.DocumentStore != undefined) {
            // try to get name from invoice form
            if (name == '') {
                const documentNameField = this.DocumentStore.FormFields.find(field => field.Ref == 'DocumentName');
                if (documentNameField != null && documentNameField != undefined)
                    if (documentNameField.Value != null && documentNameField.Value != undefined)
                        name = documentNameField.Value.trim();

            }

            // try to get email from invoice form
            if (!email) {
                const documentEmailField = this.DocumentStore.FormFields.find(field => field.Ref == 'DocumentEmail');
                if (documentEmailField != null && documentEmailField != undefined)
                    if (documentEmailField.Value != null && documentEmailField.Value != undefined)
                        email = documentEmailField.Value.trim();

            }

            // try to get mobile number from invoice form
            if (!mobilePhone) {
                const documentMobileField = this.DocumentStore.FormFields.find(field => field.Ref == 'DocumentMobile');
                if (documentMobileField != null && documentMobileField != undefined)
                    if (documentMobileField.Value != null && documentMobileField.Value != undefined)
                        mobilePhone = documentMobileField.Value.trim();
            }

            // try to get street from invoice form
            if (!street) {
                const documentStreetField = this.DocumentStore.FormFields.find(field => field.Ref == 'DocumentStreet');
                if (documentStreetField != null && documentStreetField != undefined)
                    if (documentStreetField.Value != null && documentStreetField.Value != undefined)
                        street = documentStreetField.Value.trim();
            }

            // try to get zipcode from invoice form
            if (!zipcode) {
                const documentZipcodeField = this.DocumentStore.FormFields.find(field => field.Ref == 'DocumentZipcode');
                if (documentZipcodeField != null && documentZipcodeField != undefined)
                    if (documentZipcodeField.Value != null && documentZipcodeField.Value != undefined)
                        zipcode = documentZipcodeField.Value.trim();
            }

            // try to get city from invoice form
            if (!city) {
                const documentCityField = this.DocumentStore.FormFields.find(field => field.Ref == 'DocumentCity');
                if (documentCityField != null && documentCityField != undefined)
                    if (documentCityField.Value != null && documentCityField.Value != undefined)
                        city = documentCityField.Value.trim();
            }
        }

        if (this.DeliveryStore != null && this.DeliveryStore != undefined) {
            // try to get street from delivery form
            if (!street) {
                let _streetName = '';
                let _streetNumber = '';

                const deliveryStreetField = this.DeliveryStore.StreetField;
                if (deliveryStreetField != null && deliveryStreetField != undefined)
                    if (deliveryStreetField.Value != null && deliveryStreetField.Value != undefined)
                        _streetName = deliveryStreetField.Value.trim();

                const deliveryBuildingNumberField = this.DeliveryStore.BuildingNumberField;
                if (deliveryBuildingNumberField != null && deliveryBuildingNumberField != undefined)
                    if (deliveryBuildingNumberField.Value != null && deliveryBuildingNumberField.Value != undefined)
                        _streetNumber = deliveryBuildingNumberField.Value;

                const _streetAndNumber = `${_streetName} ${_streetNumber}`.trim();
                if (_streetAndNumber != '')
                    street = _streetAndNumber;
            }

            // try to get city from delivery form
            if (!city) {
                const deliveryCityField = this.DeliveryStore.CityField;
                if (deliveryCityField != null && deliveryCityField != undefined)
                    if (deliveryCityField.Value != null && deliveryCityField.Value != undefined)
                        city = deliveryCityField.Value.trim();
            }
        }

        // build contact details object
        this.ContactDetails = {
            givenName: name,
            emailAddress: email,
            phoneNumber: mobilePhone,
            addressLines: [street || '-'],
            postalCode: zipcode || '-',
            locality: city || '-',
            countryCode: 'IL',
        };
    }

    InitRequiredContactFields() {
        if (this.PaymentStore.EmailField.Show && this.PaymentStore.EmailField.Required)
            this.RequiredContactFields.push("email");
        if (this.PaymentStore.MobileField.Show && this.PaymentStore.MobileField.Required)
            this.RequiredContactFields.push("phone");
        if (this.PaymentStore.CardOwnerNameField.Required || this.RequiredContactFields.length > 0)
            this.RequiredContactFields.push("name");
    }

    GetApplePayPaymentRequest(applicationData?: string) {

        if (!this.OrderLines || this.OrderLines.length == 0)
            this.InitOrderLines();

        if (!this.ContactDetails)
            this.InitContactDetails();

        if (!this.RequiredContactFields)
            this.InitRequiredContactFields();

        const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
            countryCode: this.CountryCode,
            currencyCode: this.CurrencyCode,
            total: this.OrderLines.pop() as ApplePayJS.ApplePayLineItem,
            merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
            supportedNetworks: this.AvailableCardBrands, // ["amex", "discover", "jcb", "masterCard", "privateLabel", "visa"],
            requiredBillingContactFields: [],// [...this.RequiredContactFields],
            requiredShippingContactFields: [],// [...this.RequiredContactFields],
            billingContact: this.ContactDetails,
            shippingContact: this.ContactDetails,
            lineItems: this.OrderLines,
            applicationData: applicationData
        };

        return paymentRequest;
    }

    CheckIfApplePayExtPage() {
        const parentWin = window.opener as typeof window;
        const matches = window.location.search.match(/ApplePayExt=true/ig);
        this._isApplePayExt = matches != null && matches.length > 0;

        if (this._isApplePayExt == false || !parentWin) return;

        this.PaymentPageWindow = parentWin;

        this.SetUIPropertiesForPopupWin();

        this.RegisterEventListenersPopupWin(parentWin);
    }

    SetUIPropertiesForPopupWin() {
        //const root = this.root;

        //root.order.showInvoiceHead(false); //root.hideInvoiceHead = true;
        //root.customFields = [];
        //root.hidePayPal = true;
        //root.hideMasterPass = true;
        //root.hideUpayBitBtn = true;
        //root.hideCreditCardInputs = true;
        //root.hideUpayBitBtn = true;
        //root.lph1 = "תשלום ב-ApplePay";

        //root.labels['PopUpRedirectTo'] = "מיד תוחזרו לדף התשלום, דף זה יסגר באופן אוטומטי.";
        //root.labels['PopUpYouClickThis'] = "במידה ולא הועברתם, ניתן לחזור לדף ע\"י לחיצה ";
        //root.PopUpRedirectTo("javascript:window.close()");

        //root.cancelURL = 'javascript:window.close()';
        //root.showCancelURL(this._isApplePayExt);
        //root.labels['LinkBackToSite'] = "חזור לדף תשלום";
    }

    RegisterEventListenersPopupWin(parentWin: Window & typeof globalThis) {
        parentWin.onunload = (event) => {  // if parent closes, close myself & payment-sheet if necessary
            this.ClosePaymentSheet();
            window.close();
        }

        window.onpagehide = (event) => {
            parentWin.focus();
        };
    }

    ClosePaymentSheet() {
        try {
            this._session.abort();
        } catch (ex) {
            console.error("could not close PaymentSheet", ex);
        }
    }

    ProcessAuthedPaymentTokenHandler: (payment: ApplePayJS.ApplePayPayment) => Promise<any>;

    ProcessAuthedPaymentTokenHandlerDefault(payment: ApplePayJS.ApplePayPayment) {
        const headers = {
            'Content-Type': 'application/json; charset=utf-8',
        };

        const url = `${process.env.VUE_APP_ROOT_API}/ApplePayAPI/ValidatePaymentToken`;

        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payment.token)
        }).then(response => response.json()).
            then((response) => {
                let resultAction = PaymentAuthorizationResultAction.FailSoCancel;

                if (response.RespId === 0) {
                    resultAction = PaymentAuthorizationResultAction.Success;
                }
                this.PushPaymentAuthResult(resultAction, response.RespDesk);

            }).
            catch((ex) => {
                console.error('ajax failed requesing >>', ex);
                this.PushPaymentAuthResult(PaymentAuthorizationResultAction.FailSoCancel, "אירעה שגיאה בלתי צפויה בנסיון לאמת את פרטי התשלום בצד השרת");
            });
    }

    GetApplePayRequest() {
        const request = {
            GoogleToken: this.GoogleToken,
            ApplePayToken: this.ApplePayPaymentToken,
            UserAgent: window.navigator.userAgent,
            NumberOfTries: 1,
            Store: {
                CommonStore: this.CommonStore.$state,
                CompanyInfoStore: this.CompanyInfoStore.$state,
                ProductsStore: this.ProductsStore.$state,
                DeliveryStore: this.DeliveryStore.$state,
                DocumentStore: this.DocumentStore.$state,
                DetailsStore: this.DetailsStore.$state,
                PaymentStore: this.PaymentStore.$state
            },
            TerminalNumber: '',
            LowProfileCode: ''
        };

        // remove unnecessary info from request
        request.Store.CommonStore.OpeningText = '';
        request.Store.ProductsStore.currencies = [];
        request.Store.DeliveryStore.Cities = [];
        request.Store.DeliveryStore.Streets = [];

        return request;
    }

    GetLanguage() {
        let lang = this.CommonStore.Language;
        if (lang == 'sp') lang = 'es';  // fix for spanish lang-code...
        if (lang == 'gr') lang = 'de';  // fix for german lang-code...
        return lang || 'en';  // if language missing, default to english.
    }


}


const ApplePayError = (window as any).ApplePayError;
declare class ApplePaySession extends EventTarget {
    /**
     * The entry point for Apple Pay on the web.
     * @param version - The version number of the ApplePay JS API you are using. The current API version number is 3.
     * @param paymentRequest - An ApplePayPaymentRequest object that contains the information to be displayed on the Apple Pay payment sheet.
     */
    constructor(version: number, paymentRequest: ApplePayJS.ApplePayPaymentRequest);

    /**
     * A callback function that is automatically called when the payment UI is dismissed.
     */
    oncancel: (event: ApplePayJS.Event) => void;

    /**
     * A callback function that is automatically called when the user has authorized the Apple Pay payment with Touch ID, Face ID, or passcode.
     */
    onpaymentauthorized: (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => void;

    /**
     * A callback function that is automatically called when a new payment method is selected.
     */
    onpaymentmethodselected: (event: ApplePayJS.ApplePayPaymentMethodSelectedEvent) => void;

    /**
     * A callback function that is called when a shipping contact is selected in the payment sheet.
     */
    onshippingcontactselected: (event: ApplePayJS.ApplePayShippingContactSelectedEvent) => void;

    /**
     * A callback function that is automatically called when a shipping method is selected.
     */
    onshippingmethodselected: (event: ApplePayJS.ApplePayShippingMethodSelectedEvent) => void;

    /**
     * A callback function that is automatically called when the payment sheet is displayed.
     */
    onvalidatemerchant: (event: ApplePayJS.ApplePayValidateMerchantEvent) => void;

    /**
     * Indicates whether the device supports Apple Pay.
     * @returns true if the device supports making payments with Apple Pay; otherwise, false.
     */
    static canMakePayments(): boolean;

    /**
     * Indicates whether the device supports Apple Pay and whether the user has an active card in Wallet.
     * @param merchantIdentifier - The merchant ID created when the merchant enrolled in Apple Pay.
     * @returns true if the device supports Apple Pay and there is at least one active card in Wallet that is qualified for payments on the web; otherwise, false.
     */
    static canMakePaymentsWithActiveCard(merchantIdentifier: string): Promise<boolean>;

    /**
     * Displays the Set up Apple Pay button.
     * @param merchantIdentifier - The merchant ID created when the merchant enrolled in Apple Pay.
     * @returns A boolean value indicating whether setup was successful.
     */
    static openPaymentSetup(merchantIdentifier: string): Promise<boolean>;

    /**
     * Verifies whether a web browser supports a given Apple Pay JS API version.
     * @param version - A number representing the Apple Pay JS API version being checked. The initial version is 1. The latest version is 3.
     * @returns A boolean value indicating whether the web browser supports the given API version. Returns false if the web browser does not support the specified version.
     */
    static supportsVersion(version: number): boolean;

    /**
     * Aborts the current Apple Pay session.
     */
    abort(): void;

    /**
     * Begins the merchant validation process.
     */
    begin(): void;

    /**
     * Completes the validation for a merchant session.
     * @param merchantSession - An opaque message session object.
     */
    completeMerchantValidation(merchantSession: any): void;

    /**
     * Completes the payment authorization with a result.
     * @param result - The status of the payment, whether it succeeded or failed for Apple Pay JS versions 1 and 2,
     * or the result of the payment authorization, including its status and list of errors for Apple Pay JS version 3.
     */
    completePayment(result: number | ApplePayJS.ApplePayPaymentAuthorizationResult): void;

    /**
     * Call after a payment method has been selected for Apple Pay JS versions 1 and 2.
     * @param newTotal - An ApplePayLineItem dictionary representing the total price for the purchase.
     * @param newLineItems - A sequence of ApplePayLineItem dictionaries.
     */
    completePaymentMethodSelection(newTotal: ApplePayJS.ApplePayLineItem, newLineItems: ApplePayJS.ApplePayLineItem[]): void;

    /**
     * Completes the selection of a payment method with an update for Apple Pay JS version 3.
     * @param update - The updated payment method.
     */
    completePaymentMethodSelection(update: ApplePayJS.ApplePayPaymentMethodUpdate): void;

    /**
     * Completes the selection of a shipping contact with an update for Apple Pay JS versions 1 and 2.
     * @param status - The status of the shipping contact update.
     * @param newShippingMethods - A sequence of ApplePayShippingMethod dictionaries.
     * @param newTotal - An ApplePayLineItem dictionary representing the total price for the purchase.
     * @param newLineItems - A sequence of ApplePayLineItem dictionaries.
     */
    completeShippingContactSelection(
        status: number,
        newShippingMethods: ApplePayJS.ApplePayShippingMethod[],
        newTotal: ApplePayJS.ApplePayLineItem,
        newLineItems: ApplePayJS.ApplePayLineItem[]): void;

    /**
     * Completes the selection of a shipping contact with an update for Apple Pay JS version 3.
     * @param update - The updated shipping contact.
     */
    completeShippingContactSelection(update: ApplePayJS.ApplePayShippingContactUpdate): void;

    /**
     * Call after the shipping method has been selected for Apple Pay JS versions 1 and 2.
     * @param status - The status of the shipping method update.
     * @param newTotal - An ApplePayLineItem dictionary representing the total price for the purchase.
     * @param newLineItems - A sequence of ApplePayLineItem dictionaries.
     */
    completeShippingMethodSelection(status: number, newTotal: ApplePayJS.ApplePayLineItem, newLineItems: ApplePayJS.ApplePayLineItem[]): void;

    /**
     * Completes the selection of a shipping method with an update for Apple Pay JS version 3.
     * @param update - The updated shipping method.
     */
    completeShippingMethodSelection(update: ApplePayJS.ApplePayShippingMethodUpdate): void;

    /**
     * The requested action succeeded.
     */
    static readonly STATUS_SUCCESS: number;

    /**
     * The requested action failed.
     */
    static readonly STATUS_FAILURE: number;

    /**
     * The billing address is not valid.
     */
    static readonly STATUS_INVALID_BILLING_POSTAL_ADDRESS: number;

    /**
     * The shipping address is not valid.
     */
    static readonly STATUS_INVALID_SHIPPING_POSTAL_ADDRESS: number;

    /**
     * The shipping contact information is not valid.
     */
    static readonly STATUS_INVALID_SHIPPING_CONTACT: number;

    /**
     * The PIN information is not valid. Cards on the China Union Pay network may require a PIN.
     */
    static readonly STATUS_PIN_INCORRECT: number;

    /**
     * The maximum number of tries for a PIN has been reached and the user has been locked out. Cards on the China Union Pay network may require a PIN.
     */
    static readonly STATUS_PIN_LOCKOUT: number;

    /**
     * The required PIN information was not provided. Cards on the China Union Pay payment network may require a PIN to authenticate the transaction.
     */
    static readonly STATUS_PIN_REQUIRED: number;

}


export { ApplePayManager }
